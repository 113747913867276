import * as Yup from "yup";

import {
  requiredArray,
  requiredText,
  requiredHTML,
} from "../../helpers/formValidators";
import { REDCAP_VALUE, SURVEY_GIZMO_VALUE } from "../../helpers/constants";

const validSurveyUrl = Yup.string()
  .trim()
  .matches(/(testmybrain\.org|surveygizmo\.(com|eu))/, {
    key: "surveyedit_form_error_valid_survey_providers",
  })
  .required();

export const SurveyEditSchemaAdmin = Yup.object().shape({
  published: Yup.boolean(),
  survey_platform: Yup.string().required(),
  redcap_url: Yup.string().when("survey_platform", {
    is: REDCAP_VALUE,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  redcap_api_token: Yup.string().when("survey_platform", {
    is: REDCAP_VALUE,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  redcap_initial_instrument: Yup.string().when("survey_platform", {
    is: REDCAP_VALUE,
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  redcap_event_id: Yup.string(),
  title: requiredText,
  title_fi: requiredText,
  title_sv: requiredText,
  start_date: Yup.date().required(),
  end_date: Yup.date().required(),
  description: requiredText,
  biobanks: requiredArray,
  project_number: requiredText,
  studyid: Yup.number().required(),
});

export const SurveyEditSchemaResearcher = (studyType) => {
  const surveyPlatformSchema = Yup.string().when("survey_platform", {
    is: (survey_platform) =>
      survey_platform === SURVEY_GIZMO_VALUE && studyType === "answearable",
    then: validSurveyUrl,
    otherwise: Yup.string(),
  });

  return Yup.object().shape({
    survey_platform: Yup.string().required(),
    redcap_url: Yup.string().when("survey_platform", {
      is: REDCAP_VALUE,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    redcap_api_token: Yup.string().when("survey_platform", {
      is: REDCAP_VALUE,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    redcap_initial_instrument: Yup.string().when("survey_platform", {
      is: REDCAP_VALUE,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    redcap_event_id: Yup.string(),
    title: requiredText,
    title_fi: requiredText,
    title_sv: requiredText,
    start_date: Yup.date().required(),
    end_date: Yup.date().required(),
    description: requiredText,
    description_fi: requiredText,
    description_sv: requiredText,
    intro: requiredHTML,
    intro_fi: requiredHTML,
    intro_sv: requiredHTML,
    thankyou: requiredHTML,
    thankyou_fi: requiredHTML,
    thankyou_sv: requiredHTML,
    survey_url: surveyPlatformSchema,
    survey_url_fi: surveyPlatformSchema,
    survey_url_sv: surveyPlatformSchema,
  });
};
