import config from "../config";
import useFetch from "./useFetch";

const useFetchSurveys = (projectId, isArchived) => {
  const studyIdString = projectId ? `&studyid=${projectId}` : "";
  const { data, loading, error, refresh } = useFetch(
    `${config.fingeniousApiUrl}/survey?deleted=${
      isArchived ? 1 : 0
    }${studyIdString}`
  );

  return {
    surveys: data,
    loading,
    error,
    refresh,
  };
};

export default useFetchSurveys;
