import axios from "../helpers/axios";
import config from "../config";
import notify from "../helpers/notification";
import { logError } from "../helpers/logger";
import { replace } from "connected-react-router";
import { getProjectListUrl } from "../helpers/linkGenerator";

import {
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESSFUL,
  PROJECT_CREATE_FAILURE,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESSFUL,
  PROJECT_UPDATE_FAILURE,
  PROJECT_DELETE,
  PROJECT_DELETE_SUCCESSFUL,
  PROJECT_DELETE_FAILURE,
  PROJECT_RESET_FORM_STATE,
} from "./project.actions";

const projectApiUrl = `${config.fingeniousApiUrl}/study`;

const notifyCreated = () => notify("saved", "projectedit_form_created_desc");
const notifyUpdated = () => notify("saved", "projectedit_form_updated_desc");
const notifyDeleted = () => notify("deleted", "project_deleted_desc");

// generic stuff
const handleChange = (dispatch, actionCreators, notifyFunction, redirectTo) => {
  actionCreators.map((action) => dispatch(action()));
  setTimeout(() => {
    dispatch(resetFormState());
  }, 3000);
  dispatch(replace(redirectTo()));
  notifyFunction();
};

const resetFormState = () => ({
  type: PROJECT_RESET_FORM_STATE,
});

// Project creation
export const projectCreate = () => ({
  type: PROJECT_CREATE,
});

export const projectCreateSuccessful = () => ({
  type: PROJECT_CREATE_SUCCESSFUL,
});

export const projectCreateFailure = (code, message) => ({
  type: PROJECT_CREATE_FAILURE,
  code,
  message,
});

export const createProject = (fields) => {
  return async (dispatch) => {
    dispatch(projectCreate());

    try {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(projectApiUrl, formData, {
        credentials: "include",
      });
      if (response.status === 200) {
        handleChange(
          dispatch,
          [projectCreateSuccessful],
          notifyCreated,
          getProjectListUrl
        );
      }
    } catch (error) {
      logError(error);
      dispatch(
        projectCreateFailure(error.response.status, error.response.data.message)
      );
    }
  };
};

// Project update
export const projectUpdate = () => ({
  type: PROJECT_UPDATE,
});

export const projectUpdateSuccessful = () => ({
  type: PROJECT_UPDATE_SUCCESSFUL,
});

export const projectUpdateFailure = (code, message) => ({
  type: PROJECT_UPDATE_FAILURE,
  code,
  message,
});

export const updateProject = (projectId, fields) => {
  return async (dispatch) => {
    dispatch(projectUpdate());

    try {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(
        projectApiUrl + `/${projectId}`,
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        handleChange(
          dispatch,
          [projectUpdateSuccessful],
          notifyUpdated,
          getProjectListUrl
        );
      }
    } catch (error) {
      logError(error);
      dispatch(
        projectUpdateFailure(error.response.status, error.response.data.message)
      );
    }
  };
};

// Project delete
export const projectDelete = () => ({
  type: PROJECT_DELETE,
});

export const projectDeleteSuccessful = () => ({
  type: PROJECT_DELETE_SUCCESSFUL,
});

export const projectDeleteFailure = (code, message) => ({
  type: PROJECT_DELETE_FAILURE,
  code,
  message,
});

export const deleteProject = (projectId) => {
  return async (dispatch) => {
    dispatch(projectDelete());
    try {
      const response = await axios.delete(projectApiUrl + `/${projectId}`, {
        credentials: "include",
      });
      if (response.status === 200) {
        handleChange(
          dispatch,
          [projectDeleteSuccessful],
          notifyDeleted,
          getProjectListUrl
        );
      }
    } catch (error) {
      logError(error);
      dispatch(
        projectDeleteFailure(error.response.status, error.response.data.message)
      );
    }
  };
};
