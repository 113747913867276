import Form from "react-bootstrap/Form";
import { Field } from "formik";
import React from "react";
import PropTypes from "prop-types";

const Select = ({ validationSchema, ...props }) => {
  const { name, options } = props;

  return (
    <Field name={name}>
      {({ field }) => (
        <Form.Control
          {...field}
          as="select"
          type="select"
          {...props}
          onChange={(e) => {
            field.onChange(e); // Call Formik's onChange
            if (props.onChange) {
              props.onChange(e); // Call the custom onChange if provided
            }
          }}
        >
          {options.map((o) => (
            <option value={o.value} key={o.value}>
              {o.label}
            </option>
          ))}
        </Form.Control>
      )}
    </Field>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Select;
