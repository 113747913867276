import * as Yup from "yup";

import { requiredText, requiredHTML } from "../../helpers/formValidators";

export const ProjectEditSchemaAdmin = Yup.object().shape({
  title: requiredText,
  title_fi: requiredText,
  title_sv: requiredText,
  description: requiredText,
  description_fi: requiredText,
  description_sv: requiredText,
  intro: requiredHTML,
  intro_fi: requiredHTML,
  intro_sv: requiredHTML,
  type: requiredText,
});

export const ProjectEditSchemaResearcher = (studyType) =>
  Yup.object().shape({
    title: requiredText,
    title_fi: requiredText,
    title_sv: requiredText,
    description: requiredText,
    description_fi: requiredText,
    description_sv: requiredText,
    intro: requiredHTML,
    intro_fi: requiredHTML,
    intro_sv: requiredHTML,
    type: requiredText,
  });
