import React, { useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useToggleSurveyPublished from "../../hooks/useToggleSurveyPublished";
import { Link } from "react-router-dom";
import { format, parse } from "date-fns";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { string, shape, bool, func } from "prop-types";
import { getSurveyEditUrl } from "../../helpers/linkGenerator";
import iconFile from "../../components/FileUploader/icon_file.svg";
import elipsis from "../../assets/img/elipsis-vertical.svg";
import styles from "./SurveyList.module.scss";
import { push } from "connected-react-router";
import useUserRoles from "../../hooks/useUserRoles";

const formatTimestamp = (timestamp) =>
  format(parse(timestamp, "yyyy-MM-dd HH:mm:ss", new Date()), "d.M.yyyy");

const Options = ({ survey, onPublishToggle }) => {
  // handle clicking outside menu collapse stuff
  const divRef = useRef();
  const buttonRef = useRef();
  const [menuActive, setMenuActive] = useState(false);
  const { isAdmin } = useUserRoles();
  useOnClickOutside(divRef, (e) => {
    if (!buttonRef.current.contains(e.target)) {
      setMenuActive(false);
    }
  });

  if (!isAdmin()) {
    return <></>;
  }

  return (
    <div className={styles.listOptions}>
      <button
        className={styles.listOptionsDropdownButton}
        ref={buttonRef}
        onClick={(e) => {
          setMenuActive(!menuActive);
          e.preventDefault();
        }}
      >
        <img src={elipsis} alt="" />
      </button>
      <div ref={divRef}>
        <OptionsDropdown
          active={menuActive}
          toggle={setMenuActive}
          survey={survey}
          onPublishToggle={onPublishToggle}
        />
      </div>
    </div>
  );
};

Options.propTypes = {
  survey: shape({
    surveyid: string.isRequired,
    title: string.isRequired,
    end_date: string.isRequired,
    published: string.isRequired,
  }),
  onPublishToggle: func.isRequired,
};

const OptionsDropdown = ({ active, survey, onPublishToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPublished = Boolean(parseInt(survey.published));
  const { doToggle } = useToggleSurveyPublished(survey.surveyid, isPublished);
  const dropdownClasses = classNames(styles.listOptionsDropdown, {
    [styles.listOptionsDropdownVisible]: active,
  });

  const { isAdmin } = useUserRoles();

  return (
    <ul className={dropdownClasses}>
      <li>
        <button
          className={styles.listOptionsDropdownButton}
          onClick={() => dispatch(push(getSurveyEditUrl(survey.surveyid)))}
          // to={getSurveyEditUrl(survey.surveyid)}
        >
          {t("surveylist_edit_survey")}
        </button>
      </li>
      {isAdmin() && (
        <li>
          <button
            className={styles.listOptionsDropdownButton}
            onClick={(e) => {
              e.preventDefault();
              doToggle(onPublishToggle);
            }}
          >
            {isPublished ? t("surveylist_unpublish") : t("surveylist_publish")}
          </button>
        </li>
      )}
    </ul>
  );
};

OptionsDropdown.propTypes = {
  active: bool,
  survey: shape({
    surveyid: string.isRequired,
    title: string.isRequired,
    end_date: string.isRequired,
    published: string.isRequired,
  }),
  onPublishToggle: func.isRequired,
};

const SurveyListItemContainer = ({ survey, projectType, onPublishToggle }) => (
  <Link className={styles.listItemLink} to={getSurveyEditUrl(survey.surveyid)}>
    <SurveyListItem
      survey={survey}
      projectType={projectType}
      onPublishToggle={onPublishToggle}
    />
  </Link>
);

const SurveyListItem = ({ survey, projectType, onPublishToggle }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);
  const isEnglish = currentLanguage === "en" || currentLanguage === "en-GB";
  const titleKey = isEnglish ? "title" : `title_${currentLanguage}`;
  const isPublished = Boolean(parseInt(survey.published));
  const { isAdmin } = useUserRoles();

  const getProjectTypeLabel = (type) => {
    return type === "answearable"
      ? t("surveylist_survey")
      : t("surveylist_announcement");
  };

  let subjects = null;
  if (isAdmin()) {
    subjects = (
      <>
        <span>
          {t("surveyedit_total_subjects")}: {survey.total_subjects}
        </span>
        <span>
          {t("surveyedit_total_participated")}: {survey.total_participated}
        </span>
      </>
    );
  }

  return (
    <div className={styles.listItem}>
      <img className={styles.icon} src={iconFile} alt={survey[titleKey]} />
      <div className={styles.surveyInfo}>
        <h2>{survey[titleKey]}</h2>
        {projectType && (
          <p className={styles.published}>{getProjectTypeLabel(projectType)}</p>
        )}
        <p className={styles.published}>
          {isPublished
            ? t("surveylist_survey_published")
            : t("surveylist_survey_unpublished")}
        </p>
        <p className={styles.subtext}>
          <span>
            {t("surveylist_survey_deadline")}
            {`: `}
            {survey.end_date ? formatTimestamp(survey.end_date) : null}
          </span>
          {isAdmin() ? subjects : ""}
        </p>
      </div>
      <Options survey={survey} onPublishToggle={onPublishToggle} />
    </div>
  );
};

SurveyListItem.propTypes = {
  survey: shape({
    surveyid: string.isRequired,
    title: string.isRequired,
    end_date: string.isRequired,
    published: string.isRequired,
  }),
  projectType: string,
  onPublishToggle: func.isRequired,
};

export default SurveyListItemContainer;
