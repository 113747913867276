import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import i18n from "../i18n";
import Papa from "papaparse";

import axios from "../helpers/axios";
import config from "../config";

import { logError } from "../helpers/logger";
import createDownload from "../helpers/fileDownloader";

import { PARTICIPANTS_GET, PARTICIPANTS_FAILURE } from "./participants.actions";

const participantsGet = () => ({ type: PARTICIPANTS_GET });

const participantsSuccess = (participants) => {
  if (participants.length) {
    const csv = createParticipantsCSV(participants);
    createDownload(csv, "participants");
  } else {
    toastr.info(i18n.t("participants_info"), i18n.t("participants_not_found"));
  }
};

const participantsFailure = (code, message) => ({
  type: PARTICIPANTS_FAILURE,
  code,
  message,
});

export const useGetParticipants = (surveyId) => {
  const dispatch = useDispatch();
  const fetchParticipants = useCallback(async () => {
    dispatch(participantsGet());
    try {
      const res = await axios.get(
        `${config.fingeniousApiUrl}/survey/${surveyId}/participants`
      );
      if (res.status === 200) {
        const participants = res.data && res.data.data ? res.data.data : [];
        participantsSuccess(participants);
      }
    } catch (e) {
      if (e.response.status === 403) {
        toastr.error(i18n.t("participants_error_403"));
      }
      logError(e);
      dispatch(participantsFailure(e.response.status, e.response.message));
    }
  }, [dispatch, surveyId]);

  return fetchParticipants;
};

const createParticipantsCSV = (participants) => {
  return Papa.unparse(participants, {
    columns: [
      "uuid",
      "surveyid",
      "email",
      "phone",
      "clicked_link",
      "participated",
      "redcap_record_id",
    ],
  });
};
