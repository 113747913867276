import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import useUserRoles from "../../hooks/useUserRoles";
import useFetchSurvey from "../../hooks/useFetchSurvey";
import useFetchProjects from "../../hooks/useFetchProjects";
import useFetchProject from "../../hooks/useFetchProject";
import { useGetContactInfo } from "../../actions/contact";
import { useTranslation } from "react-i18next";
import SurveyEditForm from "../../components/SurveyEdit/SurveyEditForm";
import SurveyView from "../../components/SurveyView/SurveyView";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import LoadingForm from "../../components/LoadingForm";
import LoadingWrapper from "../../components/LoadingWrapper";
import { useSelector } from "react-redux";
import { useHas2FAEnabled } from "../../actions/users";
import { useGetParticipants } from "../../actions/participants";
import {
  getSurveyAddUserUrl,
  getSurveyUploadSubjectsUrl,
} from "../../helpers/linkGenerator";

const SurveyEditPageUserActions = ({ surveyId, surveyType }) => {
  const { t } = useTranslation();
  const { isAdmin, isBiobankAdmin } = useUserRoles();
  const contactsDownloader = useGetContactInfo(surveyId);
  const getParticipants = useGetParticipants(surveyId);
  const check2FAStatus = useHas2FAEnabled();
  const twoFAEnabled = useSelector((state) => {
    if (!state.user.data) {
      return false;
    }
    return state.user.data.twoFactorEnabled;
  });
  const userId = useSelector((state) => {
    if (!state.user.data) {
      return null;
    }
    return state.user.data.userId;
  });
  useEffect(() => {
    if (userId) {
      check2FAStatus(userId);
    }
  }, [userId, check2FAStatus]);

  return [
    isAdmin() && (
      <Link key="add-user" to={getSurveyAddUserUrl(surveyId)}>
        <button type="button" className="button">
          + {t("survey_add_user")}
        </button>
      </Link>
    ),
    isBiobankAdmin() && (
      <Link key="upload-participants" to={getSurveyUploadSubjectsUrl(surveyId)}>
        <button type="button" className="button">
          <span>&#8593;</span> {t("survey_upload_participants")}
        </button>
      </Link>
    ),
    isBiobankAdmin() && surveyType === "answearable" && (
      <button
        key="download-participants"
        type="button"
        className="button"
        disabled={!twoFAEnabled}
        onClick={getParticipants}
      >
        <span>&#8595;</span> {t("survey_download_participants")}
      </button>
    ),
    isBiobankAdmin() && surveyType === "readonly" && (
      <button
        key="download-contact-info"
        className="button"
        onClick={() => contactsDownloader()}
      >
        <span>&#8595;</span> {t("survey_download_contact_info")}
      </button>
    ),
  ].filter(Boolean);
};

const SurveyEditPage = ({ match }) => {
  const surveyId = parseInt(match.params.surveyId);

  const { t } = useTranslation();
  const { data, loading, error } = useFetchSurvey(surveyId);
  const { isAdmin, isResearcher } = useUserRoles();
  const {
    projects,
    loading: projectsLoading,
    error: projectsError,
  } = useFetchProjects(false, !isAdmin());
  const researcherProjectStudyId = data && data.studyid ? data.studyid : "";
  const skipResearcherProject = !(isResearcher() && data && data.studyid);
  const {
    data: researcherProject,
    loading: researcherProjectLoading,
    error: researcherProjectError,
  } = useFetchProject(researcherProjectStudyId, skipResearcherProject);

  let userActions = <></>;
  let content = <></>;
  let pageTitle = "";
  if (data) {
    userActions = (
      <SurveyEditPageUserActions surveyId={surveyId} surveyType={data.type} />
    );
    content = <SurveyView surveyId={surveyId} initialValues={data} />;
    pageTitle =
      data.type === "answearable"
        ? t("survey_view_title")
        : t("survey_view_title_announcement");
    const isAllowedRole = isAdmin() || isResearcher();
    if (isAllowedRole && (projects || researcherProject)) {
      content = (
        <SurveyEditForm
          surveyId={surveyId}
          initialValues={data}
          projects={isResearcher() ? [researcherProject] : projects}
        />
      );
      pageTitle = t("survey_edit");
    }
  }

  return (
    <div className="App page-user-surveylist">
      <Title rightChildren={userActions}>{pageTitle}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <LoadingWrapper
        isLoading={loading || projectsLoading || researcherProjectLoading}
        isInErrorState={!!error || !!projectsError || !!researcherProjectError}
        errorMsg={t("surveyedit_form_error_loading")}
        loadingComponent={<LoadingForm />}
      >
        {content}
      </LoadingWrapper>
    </div>
  );
};

export default SurveyEditPage;
