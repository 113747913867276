import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import config from "./config";
import translations from "./translations.json";

export const languages = ["en", "fi", "sv"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    fallbackLng: config.defaultLanguage,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
