import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import CustomFormControl from "../CustomFormControl";
import CustomFormLabel from "../CustomFormLabel";
import {
  ProjectEditSchemaAdmin,
  ProjectEditSchemaResearcher,
} from "./ProjectEditSchema";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { createProject, updateProject } from "../../actions/project";
import { filterInitialValues } from "./projectState";
import useUserRoles from "../../hooks/useUserRoles";
import WYSIWYGTextarea from "../WYSIWYGTextarea/WYSIWYGTextarea";
import styles from "../../pages/RequestList/request-list.module.scss";
import ownStyles from "./ProjectEditForm.module.scss";
import { languages } from "../../i18n";
import Select from "../Select";

const ProjectEditForm = ({ studyId, initialValues }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAdmin, isResearcher } = useUserRoles();

  const filteredInitialValues = filterInitialValues(initialValues);

  const formikInitialValues = {
    title: "",
    title_fi: "",
    title_sv: "",
    description: "",
    description_fi: "",
    description_sv: "",
    intro: "",
    intro_fi: "",
    intro_sv: "",
    type: "answearable",
    ...filteredInitialValues,
  };

  const schema = isAdmin()
    ? ProjectEditSchemaAdmin
    : ProjectEditSchemaResearcher(initialValues.type);

  const ProjectEditLabel = (props) => (
    <CustomFormLabel validationSchema={schema} {...props} />
  );

  const ProjectEditFormControl = (props) => (
    <CustomFormControl {...props} validationSchema={schema} />
  );

  const ProjectEditFormRow = ({ children }) => (
    <Form.Row className={ownStyles.bottomMargin}>{children}</Form.Row>
  );

  const ProjectEditWYSIWYG = (props) => (
    <ProjectEditFormRow>
      <Form.Group as={Col} controlId="">
        <ProjectEditLabel fieldName={props.name}>
          {props.label}
        </ProjectEditLabel>
        <WYSIWYGTextarea {...props} />
      </Form.Group>
    </ProjectEditFormRow>
  );

  const getFieldName = (fieldName, language) => {
    return language === "en" ? fieldName : `${fieldName}_${language}`;
  };

  const getControlIdName = (fieldName, language) => {
    const prefix = "form";
    return language === "en"
      ? `${prefix}${fieldName}`
      : `${prefix}${fieldName}${language}`;
  };

  const supportedProjectTypes = [
    {
      label: t("projectlist_study_project"),
      value: "answearable",
    },
    {
      label: t("projectlist_information_return_project"),
      value: "readonly",
    },
  ];

  const getTabList = (fieldName, formProps) => (
    <TabList className={styles.tabList}>
      {languages.map((language) => (
        <Tab
          key={`${fieldName}-${language}`}
          className={`${styles.tab} ${
            formProps.errors[getFieldName(fieldName, language)]
              ? styles.tabInvalid
              : ""
          }`}
        >
          {language}
        </Tab>
      ))}
    </TabList>
  );

  const getLabel = (fieldName, language = "en") => {
    switch (fieldName) {
      case "title":
        return `${t("projectedit_form_label_title")} (${language})`;
      case "description":
        return `${t("projectedit_form_label_description")} (${language})`;
      case "intro":
        return `${t("projectedit_form_label_intro")} (${language})`;
      default:
        return "";
    }
  };

  const getMissingFields = (errors) => {
    return Object.keys(errors).map((field) => {
      const [fieldName, language] = field.split("_");
      return getLabel(fieldName, language);
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formikInitialValues}
        validationSchema={schema}
        onSubmit={(values) => {
          dispatch(
            studyId ? updateProject(studyId, values) : createProject(values)
          );
        }}
      >
        {(formProps) => (
          <Form onSubmit={formProps.handleSubmit}>
            <Container>
              <Row>
                <Col lg={4}>
                  <h2>{t("projectedit_form_heading_basic_information")}</h2>
                  <p className={"section-description"}>
                    {t("projectedit_form_desc_basic_information")}
                  </p>
                </Col>
                <Col lg={8}>
                  <ProjectEditFormRow>
                    <Form.Label>{`${t(
                      "projectedit_form_label_type"
                    )}`}</Form.Label>
                    <Form.Group as={Col} controlId="formProjectType">
                      <Select
                        name="type"
                        options={supportedProjectTypes}
                        disabled={isResearcher()}
                      />
                    </Form.Group>
                  </ProjectEditFormRow>
                  <Tabs
                    selectedTabClassName={styles.tabSelected}
                    defaultIndex={0}
                    className={ownStyles.bottomMargin}
                  >
                    {getTabList("title", formProps)}
                    {languages.map((language) => (
                      <TabPanel key={`title-${language}`}>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            controlId={getControlIdName("title", language)}
                          >
                            <ProjectEditFormControl
                              name={getFieldName("title", language)}
                              type="text"
                              label={getLabel("title", language)}
                            />
                          </Form.Group>
                        </Form.Row>
                      </TabPanel>
                    ))}
                  </Tabs>
                  <Tabs selectedTabClassName={styles.tabSelected}>
                    {getTabList("description", formProps)}
                    {languages.map((language) => (
                      <TabPanel key={`description-${language}`}>
                        <ProjectEditFormRow>
                          <Form.Group
                            as={Col}
                            controlId={getControlIdName(
                              "description",
                              language
                            )}
                          >
                            <ProjectEditFormControl
                              as={"textarea"}
                              name={getFieldName("description", language)}
                              type="text"
                              label={getLabel("description", language)}
                            />
                          </Form.Group>
                        </ProjectEditFormRow>
                      </TabPanel>
                    ))}
                  </Tabs>
                </Col>
              </Row>
              <HorizontalLine className="double-vertical-margin" />
              <Row>
                <Col lg={4}>
                  <h2>
                    {t("projectedit_form_heading_user_facing_information")}
                  </h2>
                  <p className={"section-description"}>
                    {t("projectedit_form_desc_user_facing_information")}
                  </p>
                </Col>
                <Col lg={8}>
                  {languages.map((language) => (
                    <ProjectEditFormRow key={`intro-${language}`}>
                      <Form.Group
                        as={Col}
                        controlId={getControlIdName("intro", language)}
                      >
                        <ProjectEditWYSIWYG
                          initialValue={
                            formikInitialValues[getFieldName("intro", language)]
                          }
                          label={getLabel("intro", language)}
                          name={getFieldName("intro", language)}
                          onChange={formProps.setFieldValue}
                          error={
                            formProps.errors[getFieldName("intro", language)]
                          }
                        />
                      </Form.Group>
                    </ProjectEditFormRow>
                  ))}
                </Col>
              </Row>
            </Container>

            <HorizontalLine className={"top-margin"} />
            {
              <Container>
                <Row>
                  <Col lg={{ span: 8, offset: 4 }}>
                    <div className={"button-group"}>
                      <button
                        disabled={formProps.isSubmitting}
                        className={"button"}
                        type="submit"
                      >
                        {t("projectedit_form_submit")}
                      </button>
                    </div>
                    <div className="button-group">
                      {Object.keys(formProps.errors).length > 0 && (
                        <div className={styles.tabInvalid}>
                          {t("missing_fields")}
                          <ul>
                            {getMissingFields(formProps.errors).map((field) => (
                              <li key={field}>{field}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

ProjectEditForm.propTypes = {
  studyId: PropTypes.number,
  initialValues: PropTypes.object,
};

export default ProjectEditForm;
