import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import useUserRoles from "../../hooks/useUserRoles";
import { useTranslation } from "react-i18next";
import ProjectEditForm from "../../components/ProjectEdit/ProjectEditForm";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import LoadingForm from "../../components/LoadingForm";
import LoadingWrapper from "../../components/LoadingWrapper";
import useFetchProject from "../../hooks/useFetchProject";
import { useGetStudyParticipants } from "../../actions/studyParticipants";
import { useHas2FAEnabled } from "../../actions/users";
import { useSelector } from "react-redux";

const ProjectEditPageUserActions = ({ studyId }) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserRoles();
  const getStudyParticipants = useGetStudyParticipants(studyId);
  const check2FAStatus = useHas2FAEnabled();
  const twoFAEnabled = useSelector((state) => {
    if (!state.user.data) {
      return false;
    }
    return state.user.data.twoFactorEnabled;
  });
  const userId = useSelector((state) => {
    if (!state.user.data) {
      return null;
    }
    return state.user.data.userId;
  });
  useEffect(() => {
    if (userId) {
      check2FAStatus(userId);
    }
  }, [userId, check2FAStatus]);

  return [
    isAdmin() && (
      <button
        key="download-participants"
        type="button"
        className="button"
        disabled={!twoFAEnabled}
        onClick={getStudyParticipants}
      >
        <span>&#8595;</span> {t("survey_download_participants")}
      </button>
    ),
  ].filter(Boolean);
};

const ProjectEditPage = ({ match }) => {
  const studyId = parseInt(match.params.studyId);

  const { t } = useTranslation();
  const { data, loading, error } = useFetchProject(studyId);
  const { isAdmin, isResearcher } = useUserRoles();

  if (!isAdmin() && !isResearcher()) {
    return <Redirect to="/surveys" />;
  }

  let userActions = <></>;
  let content = <></>;
  const pageTitle = t("project_edit_title");
  if (data) {
    userActions = <ProjectEditPageUserActions studyId={studyId} />;
    content = <ProjectEditForm studyId={studyId} initialValues={data} />;
  }

  return (
    <div className="App page-user-projectlist">
      <Title rightChildren={userActions}>{pageTitle}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={!!error}
        errorMsg={t("projectedit_form_error_loading")}
        loadingComponent={<LoadingForm />}
      >
        {content}
      </LoadingWrapper>
    </div>
  );
};

export default ProjectEditPage;
