import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import useFetchSurveys from "../../hooks/useFetchSurveys";

import LoadingWrapper from "../../components/LoadingWrapper";
import RequestListLoadingIndicator from "../../components/RequestListLoadingIndicator";
import EmptySurveyListPlaceholder from "./EmptySurveyListPlaceholder";

import styles from "../../pages/RequestList/request-list.module.scss";

import { archiveSuffix } from "../../helpers/linkGenerator";
import SurveyListItemContainer from "./SurveyListItem";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Surveys = ({
  surveys,
  emptyPlaceholder,
  projects,
  type = "active",
  onPublishToggle,
}) => {
  const getProjectType = (survey) => {
    if (!projects) {
      return undefined;
    }
    return projects.find((project) => project.studyid === survey.studyid)?.type;
  };
  return surveys && surveys.length > 0 ? (
    surveys.map((survey) => (
      <SurveyListItemContainer
        key={survey.surveyid}
        survey={survey}
        projectType={getProjectType(survey)}
        onPublishToggle={onPublishToggle}
      />
    ))
  ) : (
    <EmptySurveyListPlaceholder title={emptyPlaceholder} type={type} />
  );
};

const SurveyList = ({ tabIndex = 0, projectId, userId, projects }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentRoute = useLocation().pathname;

  const {
    surveys: activeSurveys,
    loading: activeSurveysLoading,
    error: activeSurveysError,
    refresh: refreshActiveSurveys,
  } = useFetchSurveys(projectId, false);

  const {
    surveys: archivedSurveys,
    loading: archivedSurveysLoading,
    error: archivedSurveysError,
    refresh: refreshArchivedSurveys,
  } = useFetchSurveys(projectId, true);

  const onPublishToggle = () => {
    refreshActiveSurveys();
    refreshArchivedSurveys();
  };

  return (
    <Tabs
      selectedTabClassName={styles.tabSelected}
      defaultIndex={tabIndex}
      onSelect={(tabIndex) =>
        dispatch(
          replace(
            tabIndex === 1
              ? `${currentRoute}${archiveSuffix}`
              : currentRoute.replace(archiveSuffix, "")
          )
        )
      }
    >
      <div className={"finbb-container"}>
        <TabList className={styles.tabList}>
          <Tab className={styles.tab}>{t("active")}</Tab>
          <Tab className={styles.tab}>{t("archived")}</Tab>
        </TabList>
      </div>
      <div className={styles.horizontalLineBelowTabList} />
      <div className={"finbb-container"}>
        <TabPanel className={styles.tabPanel}>
          <LoadingWrapper
            isLoading={activeSurveysLoading}
            isInErrorState={activeSurveysError != null}
            errorMsg={t("active_surveys_loading_error")}
            loadingComponent={<RequestListLoadingIndicator />}
          >
            <Surveys
              surveys={activeSurveys}
              type="active"
              projects={projects}
              onPublishToggle={onPublishToggle}
            />{" "}
          </LoadingWrapper>
        </TabPanel>
        <TabPanel>
          <LoadingWrapper
            isLoading={archivedSurveysLoading}
            isInErrorState={archivedSurveysError != null}
            errorMsg={t("archived_surveys_loading_error")}
            loadingComponent={<RequestListLoadingIndicator />}
          >
            <Surveys
              surveys={archivedSurveys}
              type="archived"
              projects={projects}
              onPublishToggle={onPublishToggle}
            />
          </LoadingWrapper>
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default SurveyList;
