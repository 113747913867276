import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import useUserRoles from "../../hooks/useUserRoles";
import DOMPurify from "dompurify";
import { SURVEY_GIZMO_VALUE } from "../../helpers/constants";
import styles from "./SurveyView.module.scss";

const allowedTags = [
  "b",
  "strong",
  "i",
  "em",
  "p",
  "br",
  "s",
  "ss",
  "a",
  "ol",
  "ul",
  "li",
  "img",
  "hr",
];

const SurveyViewField = ({ label, value }) => {
  const cleanedValue = DOMPurify.sanitize(value, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: ["href", "src", "alt", "width", "height", "target", "rel"],
  });

  return (
    <div className={styles.surveyViewItem}>
      <p className={styles.surveyViewLabel}>
        <strong>{label}</strong>
      </p>
      <div dangerouslySetInnerHTML={{ __html: cleanedValue }}></div>
    </div>
  );
};

const SurveyView = ({ surveyId, initialValues }) => {
  const { t, i18n } = useTranslation();
  const { isAdmin } = useUserRoles();
  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);

  const isEnglish = currentLanguage === "en" || currentLanguage === "en-GB";
  const titleKey = isEnglish ? "title" : `title_${currentLanguage}`;
  const descriptionKey = isEnglish
    ? "description"
    : `description_${currentLanguage}`;
  const introKey = isEnglish ? "intro" : `intro_${currentLanguage}`;
  const thankyouKey = isEnglish ? "thankyou" : `thankyou_${currentLanguage}`;
  const isSurvey = initialValues["type"] === "answearable";

  return (
    <div className="form">
      <Container>
        <Row>
          <Col lg={4}>
            <h2>{t("surveyedit_form_heading_basic_information")}</h2>
            <p className={"section-description"}>
              {isSurvey
                ? t("surveyedit_form_desc_basic_information")
                : t("surveyedit_form_desc_basic_information_announcement")}
            </p>
          </Col>
          <Col lg={8}>
            <Form.Row>
              <Form.Group as={Col} controlId="formTitle">
                <SurveyViewField
                  label={
                    isSurvey
                      ? t("surveyedit_form_label_title")
                      : t("surveyedit_form_label_announcement_title")
                  }
                  value={initialValues[titleKey]}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formStartDate">
                <SurveyViewField
                  label={t("surveyedit_total_subjects")}
                  value={initialValues["total_subjects"]}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formEndDate">
                <SurveyViewField
                  label={t("surveyedit_total_participated")}
                  value={initialValues["total_participated"]}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formStartDate">
                <SurveyViewField
                  label={
                    isSurvey
                      ? t("surveyedit_form_label_start_date")
                      : t("surveyedit_form_label_announcement_start_date")
                  }
                  value={initialValues["start_date"]}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formEndDate">
                <SurveyViewField
                  label={
                    isSurvey
                      ? t("surveyedit_form_label_end_date")
                      : t("surveyedit_form_label_announcement_end_date")
                  }
                  value={initialValues["end_date"]}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formDescription">
                <SurveyViewField
                  label={
                    isSurvey
                      ? t("surveyedit_form_label_description")
                      : t("surveyedit_form_label_announcement_description")
                  }
                  value={initialValues[descriptionKey]}
                />
              </Form.Group>
            </Form.Row>
            {isAdmin() && (
              <Form.Row>
                <Form.Group as={Col} controlId="formProjectNumber">
                  <SurveyViewField
                    label={t("surveyedit_form_label_project_number")}
                    value={initialValues["project_number"]}
                  />
                </Form.Group>
              </Form.Row>
            )}
          </Col>
        </Row>
        {isSurvey &&
          initialValues["survey_platform"] === SURVEY_GIZMO_VALUE && (
            <>
              <HorizontalLine className="double-vertical-margin" />
              <Row>
                <Col lg={4}>
                  <h2>{t("surveyedit_form_heading_external_survey")}</h2>
                  <p className={"section-description"}>
                    {t("surveyedit_form_desc_external_survey")}
                  </p>
                </Col>
                <Col lg={8}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formSurveyUrl">
                      <SurveyViewField
                        label={t("surveyedit_form_label_survey_url")}
                        value={initialValues["survey_url"]}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Row>
            </>
          )}
        <HorizontalLine className="double-vertical-margin" />
        <Row>
          <Col lg={4}>
            <h2>{t("surveyedit_form_heading_user_facing_information")}</h2>
            <p className={"section-description"}>
              {isSurvey
                ? t("surveyedit_form_desc_user_facing_information")
                : t(
                    "surveyedit_form_desc_user_facing_information_announcement"
                  )}
            </p>
          </Col>
          <Col lg={8}>
            <Form.Row>
              <Form.Group as={Col} controlId="formIntro">
                <SurveyViewField
                  label={
                    isSurvey
                      ? t("surveyedit_form_label_intro")
                      : t("surveyedit_form_label_announcement_intro")
                  }
                  value={initialValues[introKey]}
                />
              </Form.Group>
            </Form.Row>
            {isSurvey && (
              <Form.Row>
                <Form.Group as={Col} controlId="formThankou">
                  <SurveyViewField
                    label={t("surveyedit_form_label_thankyou")}
                    value={initialValues[thankyouKey]}
                  />
                </Form.Group>
              </Form.Row>
            )}
          </Col>
        </Row>
        <HorizontalLine className="double-vertical-margin" />
      </Container>
    </div>
  );
};

SurveyView.propTypes = {
  surveyId: PropTypes.number,
  initialValues: PropTypes.object,
};

export default SurveyView;
