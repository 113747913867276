import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { replace } from "connected-react-router";

import LoadingWrapper from "../LoadingWrapper";
import RequestListLoadingIndicator from "../RequestListLoadingIndicator";
import EmptyProjectListPlaceholder from "./EmptyProjectListPlaceholder";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import styles from "../../pages/RequestList/request-list.module.scss";
import {
  getArchivedProjectListUrl,
  getActiveProjectListUrl,
} from "../../helpers/linkGenerator";
import ProjectListItemContainer from "./ProjectListItem";
import useFetchProjects from "../../hooks/useFetchProjects";
import { updateProject, deleteProject } from "../../actions/project";

const Projects = ({
  projects,
  onArchive,
  onUnArchive,
  onDelete,
  emptyPlaceholder = "",
  type = "",
}) => {
  return projects && projects.length > 0 ? (
    projects.map((project) => (
      <ProjectListItemContainer
        key={project.studyid}
        project={project}
        onArchiveClick={onArchive}
        onUnArchiveClick={onUnArchive}
        onDeleteClick={onDelete}
      />
    ))
  ) : (
    <EmptyProjectListPlaceholder title={emptyPlaceholder} type={type} />
  );
};

const ProjectList = ({ tabIndex = 0 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    projects: activeProjects,
    loading: activeProjectsLoading,
    error: activeProjectsError,
    refresh: refreshActiveProjects,
  } = useFetchProjects();

  const {
    projects: archivedProjects,
    loading: archivedProjectsLoading,
    error: archivedProjectsError,
    refresh: refreshArchivedProjects,
  } = useFetchProjects(true);

  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isUnArchiveModalOpen, setUnArchiveModalOpen] = useState(false);
  const [projectToArchive, setProjectToArchive] = useState(null);
  const [projectToUnArchive, setProjectToUnArchive] = useState(null);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleArchive = (project) => {
    setProjectToArchive(project);
    setArchiveModalOpen(true);
  };

  const confirmArchive = async () => {
    setArchiveModalOpen(false);
    await dispatch(updateProject(projectToArchive.studyid, { deleted: 1 }));
    refreshActiveProjects();
    refreshArchivedProjects();
    setProjectToArchive(null);
  };

  const handleUnArchive = (project) => {
    setProjectToUnArchive(project);
    setUnArchiveModalOpen(true);
  };

  const confirmUnArchive = async () => {
    setUnArchiveModalOpen(false);
    await dispatch(updateProject(projectToUnArchive.studyid, { deleted: 0 }));
    refreshActiveProjects();
    refreshArchivedProjects();
    setProjectToUnArchive(null);
  };

  const handleDelete = (project) => {
    setProjectToDelete(project);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    setDeleteModalOpen(false);
    await dispatch(deleteProject(projectToDelete.studyid));
    refreshActiveProjects();
    refreshArchivedProjects();
  };

  return (
    <>
      <Tabs
        selectedTabClassName={styles.tabSelected}
        defaultIndex={tabIndex}
        onSelect={(tabIndex) =>
          dispatch(
            replace(
              tabIndex === 1
                ? getArchivedProjectListUrl()
                : getActiveProjectListUrl()
            )
          )
        }
      >
        <div className={"finbb-container"}>
          <TabList className={styles.tabList}>
            <Tab className={styles.tab}>{t("active")}</Tab>
            <Tab className={styles.tab}>{t("archived")}</Tab>
          </TabList>
        </div>
        <div className={styles.horizontalLineBelowTabList} />
        <div className={"finbb-container"}>
          <TabPanel className={styles.tabPanel}>
            <LoadingWrapper
              isLoading={activeProjectsLoading}
              isInErrorState={activeProjectsError != null}
              errorMsg={t("active_projects_loading_error")}
              loadingComponent={<RequestListLoadingIndicator />}
            >
              <Projects
                projects={activeProjects}
                type="active"
                onArchive={handleArchive}
              />
            </LoadingWrapper>
          </TabPanel>
          <TabPanel>
            <LoadingWrapper
              isLoading={archivedProjectsLoading}
              isInErrorState={archivedProjectsError != null}
              errorMsg={t("archived_projects_loading_error")}
              loadingComponent={<RequestListLoadingIndicator />}
            >
              <Projects
                projects={archivedProjects}
                type="archived"
                onUnArchive={handleUnArchive}
                onDelete={handleDelete}
              />
            </LoadingWrapper>
          </TabPanel>
        </div>
      </Tabs>
      {isArchiveModalOpen && (
        <ConfirmationModal
          isOpen={isArchiveModalOpen}
          onClose={() => setArchiveModalOpen(false)}
          onConfirm={confirmArchive}
          title={t("projectarchive_modal_title")}
          prompt={t("projectarchive_modal_prompt", {
            studyName: projectToArchive.title,
          })}
        />
      )}
      {isUnArchiveModalOpen && (
        <ConfirmationModal
          isOpen={isUnArchiveModalOpen}
          onClose={() => setUnArchiveModalOpen(false)}
          onConfirm={confirmUnArchive}
          title={t("projectunarchive_modal_title")}
          prompt={t("projectunarchive_modal_prompt", {
            studyName: projectToUnArchive.title,
          })}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          title={t("projectdelete_modal_title")}
          prompt={t("projectdelete_modal_prompt", {
            studyName: projectToDelete.title,
          })}
        />
      )}
    </>
  );
};

export default ProjectList;
