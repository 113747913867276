import React, { useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { string, shape, bool, oneOf, func } from "prop-types";
import iconFile from "../../components/FileUploader/icon_file.svg";
import elipsis from "../../assets/img/elipsis-vertical.svg";
import styles from "./ProjectList.module.scss";
import { push } from "connected-react-router";
import useUserRoles from "../../hooks/useUserRoles";
import { getProjectEditUrl } from "../../helpers/linkGenerator";

const Options = ({
  project,
  onArchiveClick,
  onUnArchiveClick,
  onDeleteClick,
}) => {
  // handle clicking outside menu collapse stuff
  const divRef = useRef();
  const buttonRef = useRef();
  const [menuActive, setMenuActive] = useState(false);
  const { isAdmin } = useUserRoles();
  useOnClickOutside(divRef, (e) => {
    if (!buttonRef.current.contains(e.target)) {
      setMenuActive(false);
    }
  });

  if (!isAdmin()) {
    return <></>;
  }

  return (
    <div className={styles.listOptions}>
      <button
        className={styles.listOptionsDropdownButton}
        ref={buttonRef}
        onClick={(e) => {
          setMenuActive(!menuActive);
          e.preventDefault();
        }}
      >
        <img src={elipsis} alt="" />
      </button>
      <div ref={divRef}>
        <OptionsDropdown
          active={menuActive}
          toggle={setMenuActive}
          project={project}
          onArchiveClick={
            onArchiveClick &&
            (() => {
              setMenuActive(false);
              onArchiveClick(project);
            })
          }
          onUnArchiveClick={
            onUnArchiveClick &&
            (() => {
              setMenuActive(false);
              onUnArchiveClick(project);
            })
          }
          onDeleteClick={
            onDeleteClick &&
            (() => {
              setMenuActive(false);
              onDeleteClick(project);
            })
          }
        />
      </div>
    </div>
  );
};

Options.propTypes = {
  project: shape({
    studyid: string.isRequired,
    title: string.isRequired,
  }),
  onArchiveClick: func,
  onUnArchiveClick: func,
  onDeleteClick: func,
};

const OptionsDropdown = ({
  active,
  project,
  onArchiveClick,
  onUnArchiveClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownClasses = classNames(styles.listOptionsDropdown, {
    [styles.listOptionsDropdownVisible]: active,
  });

  return (
    <ul className={dropdownClasses}>
      <li>
        <button
          className={styles.listOptionsDropdownButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(push(getProjectEditUrl(project.studyid)));
          }}
        >
          {t("projectlist_edit_project")}
        </button>
      </li>
      {onArchiveClick && (
        <li>
          <button
            className={styles.listOptionsDropdownButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onArchiveClick();
            }}
          >
            {t("projectlist_archive_project")}
          </button>
        </li>
      )}
      {onUnArchiveClick && (
        <li>
          <button
            className={styles.listOptionsDropdownButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onUnArchiveClick();
            }}
          >
            {t("projectlist_unarchive_project")}
          </button>
        </li>
      )}
      {onDeleteClick && (
        <li>
          <button
            className={styles.listOptionsDropdownButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteClick();
            }}
          >
            {t("projectlist_delete_project")}
          </button>
        </li>
      )}
    </ul>
  );
};

OptionsDropdown.propTypes = {
  active: bool,
  project: shape({
    studyid: string.isRequired,
    title: string.isRequired,
  }),
  onArchiveClick: func,
  onUnArchiveClick: func,
  onDeleteClick: func,
};

const ProjectListItemContainer = ({
  project,
  onArchiveClick,
  onUnArchiveClick,
  onDeleteClick,
}) => (
  <Link className={styles.listItemLink} to={getProjectEditUrl(project.studyid)}>
    <ProjectListItem
      project={project}
      onArchiveClick={onArchiveClick}
      onUnArchiveClick={onUnArchiveClick}
      onDeleteClick={onDeleteClick}
    />
  </Link>
);

const ProjectListItem = ({
  project,
  onArchiveClick,
  onUnArchiveClick,
  onDeleteClick,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);
  const isEnglish = currentLanguage === "en" || currentLanguage === "en-GB";
  const titleKey = isEnglish ? "title" : `title_${currentLanguage}`;
  const { isAdmin } = useUserRoles();
  return (
    <div className={styles.listItem}>
      <img className={styles.icon} src={iconFile} alt={project[titleKey]} />
      <div className={styles.projectInfo}>
        <h2>{project[titleKey]}</h2>
        <div className={styles.subtext}>
          <div>
            {project.type === "readonly"
              ? t("projectlist_information_return_project")
              : t("projectlist_study_project")}
          </div>
          {isAdmin() &&
            (project.type === "readonly"
              ? t("projectlist_information_return_subproject_count", {
                  count: project.survey_count,
                })
              : t("projectlist_survey_count", {
                  count: project.survey_count,
                }))}
        </div>
      </div>
      <Options
        project={project}
        onArchiveClick={onArchiveClick}
        onUnArchiveClick={onUnArchiveClick}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
};

ProjectListItem.propTypes = {
  project: shape({
    studyid: string.isRequired,
    title: string.isRequired,
    type: oneOf(["readonly", "answearable"]),
    survey_count: string,
  }),
  onArchiveClick: func,
  onUnArchiveClick: func,
  onDeleteClick: func,
};

export default ProjectListItemContainer;
