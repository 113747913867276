import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SurveyList from "../../components/SurveyList/SurveyList";
import { useSelector } from "react-redux";
import Title from "../../components/Title/Title";
import { isAdmin } from "../../helpers/session";
import useFetchProjects from "../../hooks/useFetchProjects";
import LoadingWrapper from "../../components/LoadingWrapper";
import LoadingForm from "../../components/LoadingForm";
import ProjectSelect from "../../components/ProjectSelect/ProjectSelect";

const SurveyListPageAdmin = () => {
  const {
    projects,
    loading: projectsLoading,
    error: projectsError,
  } = useFetchProjects();

  const { studyId } = useParams();
  const [selectedProjectId, setSelectedProjectId] = useState(studyId || "");
  const studyIdPrefix = studyId ? `/${studyId}` : "";
  const userData = useSelector((state) => state.user.data);
  const userId = userData.userId;
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deleted = queryParams.get("deleted") === "1";

  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
  };

  const buttons = (
    <Link to={`/surveys/new${studyIdPrefix}`}>
      <button type="button" className={"button"}>
        + {t("surveylist_new_survey")}
      </button>
    </Link>
  );

  return (
    <LoadingWrapper
      isLoading={projectsLoading}
      isInErrorState={!!projectsError}
      errorMsg={t("editprofile_form_error_loading")}
      loadingComponent={<LoadingForm />}
    >
      <div className="App page-user-surveylist">
        <Title rightChildren={buttons}>{t("surveys")}</Title>
        <ProjectSelect
          selectedProjectId={selectedProjectId}
          onProjectChange={handleProjectChange}
          projects={projects}
        />
        <SurveyList
          userId={userId}
          projectId={selectedProjectId}
          tabIndex={deleted ? 1 : 0}
          projects={projects}
        />
      </div>
    </LoadingWrapper>
  );
};

const SurveyListPage = ({ match }) => {
  const userData = useSelector((state) => state.user.data);
  const userId = userData.userId;
  const roles = userData.roles;
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deleted = queryParams.get("deleted") === "1";

  const isAdminRole = isAdmin(roles);
  if (isAdminRole) {
    return <SurveyListPageAdmin />;
  }

  return (
    <div className="App page-user-surveylist">
      <Title>{t("surveys")}</Title>
      <SurveyList userId={userId} tabIndex={deleted ? 1 : 0} />
    </div>
  );
};

export default SurveyListPage;
