import React from "react";
import { useTranslation } from "react-i18next";
import LanguageChangeControlContainer from "./LanguageChangeControlContainer";
import config from "../config";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import NavigationUser from "../components/NavigationUser/NavigationUser";
import { useSelector } from "react-redux";

const Header = ({ collapsed = false, children }) => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);

  const headerClasses = classNames({
    header: true,
    collapsed: collapsed,
  });

  const canSeeStudies =
    userData &&
    userData.roles &&
    (userData.roles.includes("ADMIN") || userData.roles.includes("RESEARCHER"));

  return (
    <header className={headerClasses}>
      <div className="finbb-container">
        <div className="nav-container">
          <nav className="primary">
            <a href={config.fingeniousMain} className="logo hide-text">
              {/* <span className="label">Admin</span> */}
            </a>
            {canSeeStudies && (
              <NavLink to="/studies" className="nav-link">
                {t("projects")}
              </NavLink>
            )}
            <NavLink to="/surveys" className="nav-link">
              {t("surveys")}
            </NavLink>
            {userData &&
              userData.roles &&
              userData.roles.includes("BIOBANK_ADMIN") && (
                <NavLink to="/consents" className="nav-link">
                  {t("consents")}
                </NavLink>
              )}
          </nav>
          <nav className="secondary">
            <LanguageChangeControlContainer className="nav-link" />
            <NavigationUser className="nav-link" />
          </nav>
        </div>
        {children}
      </div>
    </header>
  );
};

export default Header;
