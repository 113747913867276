import React from "react";
import { useTranslation } from "react-i18next";
import ProjectEditForm from "../../components/ProjectEdit/ProjectEditForm";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";

const ProjectAddPage = () => {
  const { t } = useTranslation();
  return (
    <div className="App page-project-list">
      <Title>{t("project_add_title")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <ProjectEditForm initialValues={{}} />
    </div>
  );
};

export default ProjectAddPage;
