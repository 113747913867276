import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProjectList from "../../components/ProjectList/ProjectList";
import { useSelector } from "react-redux";
import Title from "../../components/Title/Title";
import { isAdmin } from "../../helpers/session";

const ProjectListPage = ({ match }) => {
  const userData = useSelector((state) => state.user.data);
  const roles = userData.roles;
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deleted = queryParams.get("deleted") === "1";

  const buttons = isAdmin(roles) ? (
    <Link to={"/studies/new"}>
      <button type="button" className={"button"}>
        + {t("projectlist_new_project")}
      </button>
    </Link>
  ) : null;

  return (
    <div className="App page-user-surveylist">
      <Title rightChildren={buttons}>{t("projects")}</Title>
      <ProjectList tabIndex={deleted ? 1 : 0} />
    </div>
  );
};

export default ProjectListPage;
