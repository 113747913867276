import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CheckBox from "../CheckBox";
import Radio from "../RadioButton";
import { useTranslation } from "react-i18next";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import CustomFormControl from "../CustomFormControl";
import CustomFormLabel from "../CustomFormLabel";
import {
  SurveyEditSchemaAdmin,
  SurveyEditSchemaResearcher,
} from "./SurveyEditSchema";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { createSurvey, updateSurvey } from "../../actions/survey";
import { hasServerError } from "../../helpers/serverErrorHelper";
import ErrorBox from "../ErrorBox";
import { filterInitialValues } from "./surveyState";
import DatePickerFormControl from "../DatePickerFormControl/DatePickerFormControl";
import useUserRoles from "../../hooks/useUserRoles";
import WYSIWYGTextarea from "../WYSIWYGTextarea/WYSIWYGTextarea";
import { FormLabel } from "react-bootstrap";
import styles from "../../pages/RequestList/request-list.module.scss";
import ownStyles from "./SurveyEditForm.module.scss";
import { languages } from "../../i18n";
import Select from "../Select";
import {
  REDCAP_LABEL,
  REDCAP_VALUE,
  SURVEY_GIZMO_LABEL,
  SURVEY_GIZMO_VALUE,
} from "../../helpers/constants";

const SurveyEditForm = ({ surveyId, initialValues, projects }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errorCode } = useSelector((state) => state.forms.updateProfile);
  const { isAdmin, isResearcher } = useUserRoles();

  const filteredInitialValues = filterInitialValues(initialValues);

  const formikInitialValues = {
    published: "0",
    title: "",
    survey_platform: SURVEY_GIZMO_VALUE,
    redcap_url: "",
    redcap_initial_instrument: "",
    redcap_event: "",
    redcap_api_token: "",
    biobanks: [],
    title_fi: "",
    title_sv: "",
    start_date: "",
    end_date: "",
    description: "",
    description_fi: "",
    description_sv: "",
    project_number: "",
    intro: "",
    intro_fi: "",
    intro_sv: "",
    thankyou: "",
    thankyou_fi: "",
    thankyou_sv: "",
    survey_url: "",
    survey_url_fi: "",
    survey_url_sv: "",
    studyid: "",
    ...filteredInitialValues,
  };

  // clean out redundant fields
  if (isResearcher()) {
    delete formikInitialValues.published;
    delete formikInitialValues.biobanks;
    delete formikInitialValues.studyid;
  }

  const schema = isAdmin()
    ? SurveyEditSchemaAdmin
    : SurveyEditSchemaResearcher(initialValues.type);

  // Should always be kept in sync with biobank-admin-api/app/src/Helper/SurveyPlatform
  const supportedSurveyPlatform = [
    {
      label: SURVEY_GIZMO_LABEL,
      value: SURVEY_GIZMO_VALUE,
    },
    {
      label: REDCAP_LABEL,
      value: REDCAP_VALUE,
    },
  ];

  const getFieldName = (fieldName, language) => {
    return language === "en" ? fieldName : `${fieldName}_${language}`;
  };

  const getControlIdName = (fieldName, language) => {
    const prefix = "form";
    return language === "en"
      ? `${prefix}${fieldName}`
      : `${prefix}${fieldName}${language}`;
  };

  const getTabList = (fieldName, formProps) => (
    <TabList className={styles.tabList}>
      {languages.map((language) => (
        <Tab
          key={language}
          className={`${styles.tab} ${
            formProps.errors[getFieldName(fieldName, language)]
              ? styles.tabInvalid
              : ""
          }`}
        >
          {language}
        </Tab>
      ))}
    </TabList>
  );

  const SurveyEditLabel = (props) => (
    <CustomFormLabel validationSchema={schema} {...props} />
  );

  const SurveyEditFormControl = (props) => (
    <CustomFormControl {...props} validationSchema={schema} />
  );

  const SurveyEditCheckBox = (props) => (
    <CheckBox {...props} validationSchema={schema} />
  );

  const SurveyEditRadio = (props) => (
    <Radio {...props} validationSchema={schema} />
  );

  const SurveyEditWYSIWYG = (props) => (
    <SurveyFormRow>
      <Form.Group as={Col} controlId="">
        <SurveyEditLabel fieldName={props.name}>{props.label}</SurveyEditLabel>
        <WYSIWYGTextarea {...props} />
      </Form.Group>
    </SurveyFormRow>
  );

  const getProjectsOptions = () => {
    const unselectedProject = {
      label: t("surveyedit_form_choose_study"),
      value: "",
    };
    return [
      unselectedProject,
      ...(projects?.map((project) => ({
        label: project.title,
        value: project.studyid,
      })) || []),
    ];
  };

  const getProjectType = useCallback(
    (studyid) => {
      if (!projects) {
        return undefined;
      }
      const project = projects.find((project) => project.studyid === studyid);
      return project?.type ?? "answearable";
    },
    [projects]
  );

  const getProjectTypeLabel = (type) => {
    return type === "answearable"
      ? t("projectlist_study_project")
      : t("projectlist_information_return_project");
  };

  const [selectedProjectType, setSelectedProjectType] = useState(
    getProjectType(initialValues.studyid)
  );

  useEffect(() => {
    setSelectedProjectType(getProjectType(initialValues.studyid));
  }, [initialValues.studyid, getProjectType]);

  const SurveyFormRow = ({ children }) => (
    <Form.Row className={ownStyles.bottomMargin}>{children}</Form.Row>
  );

  const getLabel = (fieldName) => {
    switch (fieldName) {
      case "title_fi":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_title")} (fi)`
          : `${t("surveyedit_form_label_announcement_title")} (fi)`;
      case "title_sv":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_title")} (sv)`
          : `${t("surveyedit_form_label_announcement_title")} (sv)`;
      case "title":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_title")} (en)`
          : `${t("surveyedit_form_label_announcement_title")} (en)`;
      case "description_fi":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_description")} (fi)`
          : `${t("surveyedit_form_label_announcement_description")} (fi)`;
      case "description_sv":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_description")} (sv)`
          : `${t("surveyedit_form_label_announcement_description")} (sv)`;
      case "description":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_description")} (en)`
          : `${t("surveyedit_form_label_announcement_description")} (en)`;
      case "intro_fi":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_intro")} (fi)`
          : `${t("surveyedit_form_label_announcement_intro")} (fi)`;
      case "intro_sv":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_intro")} (sv)`
          : `${t("surveyedit_form_label_announcement_intro")} (sv)`;
      case "intro":
        return selectedProjectType === "answearable"
          ? `${t("surveyedit_form_label_intro")} (en)`
          : `${t("surveyedit_form_label_announcement_intro")} (en)`;
      case "thankyou_fi":
        return `${t("surveyedit_form_label_thankyou")} (fi)`;
      case "thankyou_sv":
        return `${t("surveyedit_form_label_thankyou")} (sv)`;
      case "thankyou":
        return `${t("surveyedit_form_label_thankyou")} (en)`;
      case "start_date":
        return selectedProjectType === "answearable"
          ? t("surveyedit_form_label_start_date")
          : t("surveyedit_form_label_announcement_start_date");
      case "end_date":
        return selectedProjectType === "answearable"
          ? t("surveyedit_form_label_end_date")
          : t("surveyedit_form_label_announcement_end_date");
      case "project_number":
        return t("surveyedit_form_label_project_number");
      case "biobanks":
        return t("surveyedit_choose_biobanks");
      case "studyid":
        return t("surveyedit_form_label_project");
      case "redcap_api_token":
        return t("surveyedit_form_label_redcap_api_token");
      case "redcap_url":
        return t("surveyedit_form_label_redcap_url");
      case "redcap_initial_instrument":
        return t("surveyedit_form_label_redcap_initial_instrument");
      case "redcap_event":
        return t("surveyedit_form_label_redcap_event");
      case "survey_url":
        return `${t("surveyedit_form_label_survey_url")} (en)`;
      case "survey_url_fi":
        return `${t("surveyedit_form_label_survey_url")} (fi)`;
      case "survey_url_sv":
        return `${t("surveyedit_form_label_survey_url")} (sv)`;
      default:
        return "";
    }
  };

  const getMissingFields = (errors) => {
    return Object.keys(errors).map((field) => {
      return getLabel(field);
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formikInitialValues}
        validationSchema={schema}
        onSubmit={(values) => {
          dispatch(
            surveyId ? updateSurvey(surveyId, values) : createSurvey(values)
          );
        }}
      >
        {(formProps) => (
          <Form onSubmit={formProps.handleSubmit}>
            <Container>
              {hasServerError(errorCode) && (
                <ErrorBox msg={t(`surveyedit_form_error_${errorCode}`)} />
              )}
              <Row>
                <Col lg={4}>
                  <h2>{t("surveyedit_form_heading_basic_information")}</h2>
                  <p className={"section-description"}>
                    {selectedProjectType === "answearable"
                      ? t("surveyedit_form_desc_basic_information")
                      : t(
                          "surveyedit_form_desc_basic_information_announcement"
                        )}
                  </p>
                </Col>
                <Col lg={8}>
                  {isResearcher() && (
                    <SurveyFormRow>
                      <FormLabel>{`${t(
                        "surveyedit_form_label_project"
                      )}`}</FormLabel>
                      <Form.Group as={Col} controlId="formProject">
                        <Select
                          disabled={isResearcher()}
                          name="studyidforresearcher"
                          value={initialValues.studyid}
                          options={getProjectsOptions()}
                        />
                      </Form.Group>
                      {selectedProjectType && (
                        <div>
                          <p>
                            <i>{getProjectTypeLabel(selectedProjectType)}</i>
                          </p>
                        </div>
                      )}
                    </SurveyFormRow>
                  )}
                  {isAdmin() && (
                    <>
                      <SurveyFormRow>
                        <FormLabel>{`${t(
                          "surveyedit_form_label_project"
                        )}`}</FormLabel>
                        <Form.Group as={Col} controlId="formProject">
                          <Select
                            disabled={isResearcher()}
                            name="studyid"
                            options={getProjectsOptions()}
                            onChange={(event) => {
                              formProps.setFieldValue(
                                "studyid",
                                event.target.value
                              );
                              const selectedProject = projects.find(
                                (project) =>
                                  project.studyid === event.target.value
                              );
                              setSelectedProjectType(selectedProject?.type);
                            }}
                          />
                        </Form.Group>
                        {selectedProjectType && (
                          <div>
                            <p>
                              <i>{getProjectTypeLabel(selectedProjectType)}</i>
                            </p>
                          </div>
                        )}
                      </SurveyFormRow>
                      <SurveyFormRow>
                        <Form.Group id="formPublished">
                          <div>
                            <SurveyEditLabel
                              withErrorIndicator
                              formProps={formProps}
                              fieldName={"published"}
                            >
                              {selectedProjectType === "answearable"
                                ? t("surveyedit_form_is_published")
                                : t(
                                    "surveyedit_form_is_announcement_published"
                                  )}
                            </SurveyEditLabel>
                          </div>

                          <SurveyEditRadio
                            label={t("yes")}
                            name={"published"}
                            value={"1"}
                            inline
                          />
                          <SurveyEditRadio
                            label={t("no")}
                            name={"published"}
                            value={"0"}
                            inline
                          />
                        </Form.Group>
                      </SurveyFormRow>
                    </>
                  )}
                  <Tabs
                    selectedTabClassName={styles.tabSelected}
                    defaultIndex={0}
                    className={ownStyles.bottomMargin}
                  >
                    {getTabList("title", formProps)}
                    {languages.map((language) => (
                      <TabPanel key={`title-${language}`}>
                        <SurveyFormRow>
                          <Form.Group
                            as={Col}
                            controlId={getControlIdName("title", language)}
                          >
                            <SurveyEditFormControl
                              name={getFieldName("title", language)}
                              type="text"
                              label={`${t(
                                selectedProjectType === "answearable"
                                  ? "surveyedit_form_label_title"
                                  : "surveyedit_form_label_announcement_title"
                              )} (${language})`}
                            />
                          </Form.Group>
                        </SurveyFormRow>
                      </TabPanel>
                    ))}
                  </Tabs>
                  <Tabs
                    selectedTabClassName={styles.tabSelected}
                    defaultIndex={0}
                    className={ownStyles.bottomMargin}
                  >
                    {getTabList("description", formProps)}
                    {languages.map((language) => (
                      <TabPanel key={`description-${language}`}>
                        <SurveyFormRow>
                          <Form.Group
                            as={Col}
                            controlId={getControlIdName(
                              "description",
                              language
                            )}
                          >
                            <SurveyEditFormControl
                              as={"textarea"}
                              name={getFieldName("description", language)}
                              type="text"
                              label={`${t(
                                selectedProjectType === "answearable"
                                  ? "surveyedit_form_label_description"
                                  : "surveyedit_form_label_announcement_description"
                              )} (${language})`}
                            />
                          </Form.Group>
                        </SurveyFormRow>
                      </TabPanel>
                    ))}
                  </Tabs>
                  <SurveyFormRow>
                    <Form.Group as={Col} controlId="formStartDate">
                      <div>
                        <SurveyEditLabel
                          withErrorIndicator
                          formProps={formProps}
                          fieldName={"start_date"}
                        >
                          {selectedProjectType === "answearable"
                            ? t("surveyedit_form_label_start_date")
                            : t(
                                "surveyedit_form_label_announcement_start_date"
                              )}
                        </SurveyEditLabel>
                      </div>

                      <DatePickerFormControl
                        name="start_date"
                        placeholderText={t("surveyedit_form_select_date")}
                        dateFormat="yyyy-MM-dd"
                        // timeFormat="HH:mm"
                      />
                    </Form.Group>
                  </SurveyFormRow>
                  <SurveyFormRow>
                    <Form.Group as={Col} controlId="formEndDate">
                      <div>
                        <SurveyEditLabel
                          withErrorIndicator
                          formProps={formProps}
                          fieldName={"end_date"}
                        >
                          {selectedProjectType === "answearable"
                            ? t("surveyedit_form_label_end_date")
                            : t("surveyedit_form_label_announcement_end_date")}
                        </SurveyEditLabel>
                      </div>
                      <DatePickerFormControl
                        name="end_date"
                        placeholderText={t("surveyedit_form_select_date")}
                        dateFormat="yyyy-MM-dd"
                      />
                    </Form.Group>
                  </SurveyFormRow>
                  {isAdmin() && (
                    <SurveyFormRow>
                      <Form.Group as={Col} controlId="formProjectNumber">
                        <SurveyEditFormControl
                          name="project_number"
                          type="text"
                          label={t("surveyedit_form_label_project_number")}
                        />
                      </Form.Group>
                    </SurveyFormRow>
                  )}
                  {isAdmin() && (
                    <Form.Group id="formBiobanks" controlId={"formBiobanks"}>
                      <SurveyEditLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"formBiobanks"}
                      >
                        {t("surveyedit_choose_biobanks")}
                      </SurveyEditLabel>
                      <SurveyEditCheckBox
                        label={t("auria_biobank")}
                        name={"biobanks"}
                        value={"10"}
                      />
                      <SurveyEditCheckBox
                        label={t("helsinki_biobank")}
                        name={"biobanks"}
                        value={"20"}
                      />
                      <SurveyEditCheckBox
                        label={t("biobank_of_eastern_finland")}
                        name={"biobanks"}
                        value={"30"}
                      />
                      <SurveyEditCheckBox
                        label={t("central_finland_biobank")}
                        name={"biobanks"}
                        value={"40"}
                      />
                      <SurveyEditCheckBox
                        label={t("northern_finland_biobank_borealis")}
                        name={"biobanks"}
                        value={"50"}
                      />
                      <SurveyEditCheckBox
                        label={t("tampere_biobank")}
                        name={"biobanks"}
                        value={"60"}
                      />
                      <SurveyEditCheckBox
                        label={t("arctic_biobank")}
                        name={"biobanks"}
                        value={"70"}
                      />
                      <SurveyEditCheckBox
                        label={t("thl_biobank")}
                        name={"biobanks"}
                        value={"80"}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <HorizontalLine className="double-vertical-margin" />
              {selectedProjectType === "answearable" && (
                <>
                  <Row>
                    <Col lg={4}>
                      <h2>{t("surveyedit_form_heading_external_survey")}</h2>
                      <p className={"section-description"}>
                        {t("surveyedit_form_desc_external_survey")}
                      </p>
                    </Col>
                    <Col lg={8}>
                      <SurveyFormRow>
                        <FormLabel>{`${t(
                          "surveyedit_form_label_platform"
                        )}`}</FormLabel>
                        <Form.Group as={Col} controlId="formSurveyPlatform">
                          <Select
                            name="survey_platform"
                            options={supportedSurveyPlatform}
                          />
                        </Form.Group>
                      </SurveyFormRow>
                      {formProps.values.survey_platform === REDCAP_VALUE && (
                        <>
                          <SurveyFormRow>
                            <Form.Group as={Col} controlId="redcapUrl">
                              <SurveyEditFormControl
                                name="redcap_url"
                                type="text"
                                label={t("surveyedit_form_label_redcap_url")}
                              />
                            </Form.Group>
                          </SurveyFormRow>
                          <SurveyFormRow>
                            <Form.Group
                              as={Col}
                              controlId="redcapInitialInstrument"
                            >
                              <SurveyEditFormControl
                                name="redcap_initial_instrument"
                                type="text"
                                label={t(
                                  "surveyedit_form_label_redcap_initial_instrument"
                                )}
                              />
                            </Form.Group>
                          </SurveyFormRow>
                          <SurveyFormRow>
                            <Form.Group as={Col} controlId="redcapEvent">
                              <SurveyEditFormControl
                                name="redcap_event"
                                type="text"
                                label={t("surveyedit_form_label_redcap_event")}
                              />
                            </Form.Group>
                          </SurveyFormRow>
                          <SurveyFormRow>
                            <Form.Group as={Col} controlId="redcapApiToken">
                              <SurveyEditFormControl
                                name="redcap_api_token"
                                type="text"
                                label={t(
                                  "surveyedit_form_label_redcap_api_token"
                                )}
                              />
                            </Form.Group>
                          </SurveyFormRow>
                        </>
                      )}
                      {formProps.values.survey_platform ===
                        SURVEY_GIZMO_VALUE && (
                        <Tabs
                          selectedTabClassName={styles.tabSelected}
                          defaultIndex={0}
                          className={ownStyles.bottomMargin}
                        >
                          {getTabList("survey_url", formProps)}
                          {languages.map((language) => (
                            <TabPanel key={`survey-url-${language}`}>
                              <SurveyFormRow>
                                <Form.Group
                                  as={Col}
                                  controlId={getControlIdName(
                                    "surveyUrl",
                                    language
                                  )}
                                >
                                  <SurveyEditFormControl
                                    name={getFieldName("survey_url", language)}
                                    type="text"
                                    label={`${t(
                                      "surveyedit_form_label_survey_url"
                                    )} (${language})`}
                                    canReportRawError
                                  />
                                </Form.Group>
                              </SurveyFormRow>
                            </TabPanel>
                          ))}
                        </Tabs>
                      )}
                    </Col>
                  </Row>
                  <HorizontalLine className="double-vertical-margin" />
                </>
              )}
              <Row>
                <Col lg={4}>
                  <h2>
                    {t("surveyedit_form_heading_user_facing_information")}
                  </h2>
                  <p className={"section-description"}>
                    {selectedProjectType === "answearable"
                      ? t("surveyedit_form_desc_user_facing_information")
                      : t(
                          "surveyedit_form_desc_user_facing_information_announcement"
                        )}
                  </p>
                </Col>
                <Col lg={8}>
                  {languages.map((language) => (
                    <SurveyFormRow key={`intro-${language}`}>
                      <Form.Group
                        as={Col}
                        controlId={getControlIdName("intro", language)}
                      >
                        <SurveyEditWYSIWYG
                          initialValue={
                            formikInitialValues[getFieldName("intro", language)]
                          }
                          label={`${t(
                            selectedProjectType === "answearable"
                              ? "surveyedit_form_label_intro"
                              : "surveyedit_form_label_announcement_intro"
                          )} (${language})`}
                          name={getFieldName("intro", language)}
                          onChange={formProps.setFieldValue}
                          error={
                            formProps.errors[getFieldName("intro", language)]
                          }
                        />
                      </Form.Group>
                    </SurveyFormRow>
                  ))}
                  {selectedProjectType === "answearable" &&
                    languages.map((language) => (
                      <SurveyFormRow key={`thankyou-${language}`}>
                        <Form.Group
                          as={Col}
                          controlId={getControlIdName("thankyou", language)}
                        >
                          <SurveyEditWYSIWYG
                            initialValue={
                              formikInitialValues[
                                getFieldName("thankyou", language)
                              ]
                            }
                            name={getFieldName("thankyou", language)}
                            label={`${t(
                              "surveyedit_form_label_thankyou"
                            )} (${language})`}
                            onChange={formProps.setFieldValue}
                            error={
                              formProps.errors[
                                getFieldName("thankyou", language)
                              ]
                            }
                          />
                        </Form.Group>
                      </SurveyFormRow>
                    ))}
                </Col>
              </Row>
            </Container>

            <HorizontalLine className={"top-margin"} />
            {
              <Container>
                <Row>
                  <Col lg={{ span: 8, offset: 4 }}>
                    <div className={"button-group"}>
                      <button
                        disabled={formProps.isSubmitting}
                        className={"button"}
                        type="submit"
                        // onClick={() => {
                        //   formProps.setFieldValue(
                        //     "submitForApprovalClicked",
                        //     true,
                        //     false
                        //   );
                        //   formProps.handleSubmit();
                        // }}
                      >
                        {t("surveyedit_form_submit")}
                      </button>
                    </div>
                    <div className="button-group">
                      {getMissingFields(formProps.errors).length > 0 && (
                        <div className={styles.tabInvalid}>
                          {t("missing_fields")}
                          <ul>
                            {getMissingFields(formProps.errors).map((field) => (
                              <li key={field}>{field}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

SurveyEditForm.propTypes = {
  surveyId: PropTypes.number,
  initialValues: PropTypes.object,
};

export default SurveyEditForm;
