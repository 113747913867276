import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import styles from "./ConfirmationModal.module.scss";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, prompt }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.confirmationModal}>
      <Modal show={isOpen} onHide={onClose} className={styles.modalContent}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{prompt}</p>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button variant="secondary" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            {t("yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
};

export default ConfirmationModal;
