import React from "react";
import util from "util";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import useImportSubjects from "../../hooks/useImportSubjects";
import FileUploader from "../FileUploader/RequestFileUploader";
import { useTranslation } from "react-i18next";

import { getSurveyEditUrl } from "../../helpers/linkGenerator";

import styles from "./SurveyUploadSubjects.module.scss";

const Progress = ({ progress, total }) => {
  const width = Math.floor((progress / total) * 100);

  return (
    <div className={styles.progressBar}>
      <span style={{ width: `${width}%` }}></span>
    </div>
  );
};

const UploadInstructions = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t("upload_subjects_instructions_title")}</h4>
      <p
        dangerouslySetInnerHTML={{
          __html: t("upload_subjects_instructions_content"),
        }}
      ></p>
      <div>
        <strong>{t("upload_subjects_biobank_ids")}</strong>
        <ul>
          <li>Auria: 10</li>
          <li>Helsinki: 20</li>
          <li>Itä-Suomi: 30</li>
          <li>Keski-Suomi: 40</li>
          <li>Borealis: 50</li>
          <li>Tampere: 60</li>
          <li>Arctic: 70</li>
          <li>THL: 80</li>
        </ul>
      </div>
    </>
  );
};

const SurveyUploadComplete = ({ jobId, surveyId, total }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("upload_subjects_upload_complete_title")}</h4>
      <p>{util.format(t("upload_subjects_upload_complete_content"), total)}</p>
      <p>Job #: {jobId}</p>
      <p>
        <NavLink className="button" to={getSurveyEditUrl(surveyId)}>
          {t("back_to_survey")}
        </NavLink>
      </p>
    </>
  );
};

const SurveyUploadProgress = ({ jobId, progress, total }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("upload_subjects_upload_progress_title")}</h4>
      <Progress progress={progress} total={total} />
      <p>
        {util.format(
          t("upload_subjects_upload_progress_content"),
          progress,
          total
        )}
      </p>
      <p>Job #: {jobId}</p>
    </>
  );
};

const SurveyUploadSubjects = ({ surveyId }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      csv: "",
    },
    onSubmit: async (values) => {
      doImport(values.csv);
    },
  });

  const { started, complete, jobId, total, progress, error, doImport } =
    useImportSubjects(surveyId, 2000);

  const SubmitRow = () => (
    <div className={`form-submit-row ${styles.formSubmit}`}>
      <div className="button-group flex-start">
        <button
          className={"button"}
          type="submit"
          disabled={formik.isSubmitting}
        >
          {t("upload")}
        </button>

        <button
          disabled={formik.isSubmitting}
          className={"button button-secondary"}
          type="submit"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );

  if (error && error.message) {
    return (
      <Container>
        <p>
          Something went wrong. Please contact support and provide job # {jobId}{" "}
          and the following error:
        </p>
        <p>{error.message}</p>
      </Container>
    );
  }

  return (
    <Container>
      {!started && (
        <>
          <UploadInstructions />
          <form onSubmit={formik.handleSubmit}>
            <FileUploader
              acceptedMimeTypes={["text/csv", ".csv"]}
              multiple={false}
              maxFiles={1}
              acceptedExtensions={["csv"]}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length === 0) {
                  return;
                }
                formik.setFieldValue("csv", acceptedFiles[0]);
              }}
            />
            <SubmitRow />
          </form>
        </>
      )}
      {started && !complete && (
        <SurveyUploadProgress jobId={jobId} total={total} progress={progress} />
      )}
      {complete && (
        <SurveyUploadComplete
          jobId={jobId}
          surveyId={surveyId}
          total={progress}
        />
      )}
    </Container>
  );
};

export default SurveyUploadSubjects;
