import ProjectListPage from "../pages/Projects/ProjectListPage";
import ProjectEditPage from "../pages/Projects/ProjectEditPage";
import ProjectAddPage from "../pages/Projects/ProjectAddPage";
const projectsUrl = "/studies";

const routes = [
  {
    path: projectsUrl,
    private: true,
    exact: true,
    component: ProjectListPage,
  },
  {
    path: `${projectsUrl}/new`,
    private: true,
    exact: true,
    component: ProjectAddPage,
  },
  {
    path: `${projectsUrl}/:studyId/edit`,
    private: true,
    exact: true,
    component: ProjectEditPage,
  },
];

export default routes;
