import config from "../config";
import useFetch from "./useFetch";

const useFetchProject = (id, skip = false) => {
  const { data, loading, error, refresh } = useFetch(
    `${config.fingeniousApiUrl}/study/${id}`,
    skip
  );

  return {
    data,
    loading,
    error,
    refresh,
  };
};

export default useFetchProject;
