import React, { useState } from "react";
import useFetchSurveyUsers from "../../hooks/useFetchSurveyUsers";
import { Container, Row, Col } from "react-bootstrap";
import SurveyFindUser from "../SurveyFindUser/SurveyFindUser";
import SelectBiobank from "../SurveySelectBiobank/SurveySelectBiobank";
import config from "../../config";
import axios from "../../helpers/axios";
import { useTranslation } from "react-i18next";
import styles from "./SurveyAddUser.module.scss";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { getSurveyEditUrl } from "../../helpers/linkGenerator";
import { NavLink } from "react-router-dom";

const surveyApiUrl = `${config.fingeniousApiUrl}/survey`;
const userApiUrl = `${config.fingeniousApiUrl}/user`;

const handleError = (e, setProcessing) => {
  setProcessing(false);
  const text = e.response.data ? e.response.data.message : "Unknown error";
  alert(text);
  return null;
};

const SurveyAddUserListUser = ({ surveyId, user, onDelete }) => {
  const { t } = useTranslation();

  const doDeleteUser = async () => {
    try {
      const response = await axios.delete(
        `${surveyApiUrl}/${surveyId}/user/${user.userid}`
      );

      if (response.status === 200) {
        onDelete();
        return response;
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  return (
    <li className={styles.userContainer}>
      {user.email}
      {user.can_handle_subjects === "1" ? ` - BIOBANK_ADMIN` : ` - RESEARCHER`}
      <button className="button button-alert" onClick={doDeleteUser}>
        {t("survey_add_user_delete")}
      </button>
    </li>
  );
};

const SurveyAddUserList = ({ surveyId, users, onDelete }) => {
  const { t } = useTranslation();
  let list = null;
  if (users && users.length) {
    list = (
      <ul className={styles.userList}>
        {users.map((user) => (
          <SurveyAddUserListUser
            key={user.userid}
            surveyId={surveyId}
            user={user}
            onDelete={onDelete}
          />
        ))}
      </ul>
    );
  }

  return (
    <>
      <h2 className={styles.subheader}>
        {t("survey_add_user_existing_users")}
      </h2>
      {list}
    </>
  );
};

const SurveyAddUserForm = ({ surveyId, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [userRole, setUserRole] = useState("researcher");
  const [biobank, setBiobank] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const isUserSelected = selectedValue && selectedValue.userid;

  const userHasBiobank = selectedValue && selectedValue.biobankId;
  const showBiobankSelect = userRole === "biobank" && isUserSelected;

  const doAddUser = async (canHandleSubjects) => {
    setProcessing(true);

    if (!isUserSelected) {
      return;
    }

    const formData = new FormData();
    formData.append("userid", selectedValue.userid);
    formData.append("can_handle_subjects", canHandleSubjects ? 1 : 0);

    try {
      const response = await axios.post(
        `${surveyApiUrl}/${surveyId}/user`,
        formData
      );

      setProcessing(false);

      if (response.status === 200) {
        return response;
      }
    } catch (e) {
      handleError(e, setProcessing);
    }
    return null;
  };

  const doAddBiobank = async () => {
    if (!userHasBiobank && showBiobankSelect && biobank) {
      try {
        const formData = new FormData();
        formData.append("biobankid", biobank.value);
        const response = await axios.post(
          `${userApiUrl}/${selectedValue.userid}/biobank`,
          formData
        );

        if (response.status === 200) {
          return response;
        }
      } catch (e) {
        handleError(e, setProcessing);
      }
      return null;
    }
  };

  const doAddResearcher = async () => {
    const response = await doAddUser(false);
    onChange();
    return response;
  };
  const doAddBiobankUser = async () => {
    const response = await doAddUser(true);
    const bbIdResponse = await doAddBiobank();
    onChange();
    return response || bbIdResponse;
  };

  let spinner = null;
  if (processing) {
    spinner = <span>...</span>;
  }

  const options = (
    <div className={styles.options}>
      <label>
        <input
          name="type"
          type="radio"
          value="researcher"
          checked={userRole === "researcher"}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setUserRole("researcher");
            }
          }}
        />{" "}
        Add as researcher
      </label>
      <label>
        <input
          name="type"
          type="radio"
          value="biobank"
          checked={userRole === "biobank"}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setUserRole("biobank");
            }
          }}
        />{" "}
        Add as biobank admin
      </label>
    </div>
  );

  const submit = (
    <div className={styles.submitButtons}>
      <NavLink
        className="button button-secondary"
        to={getSurveyEditUrl(surveyId)}
      >
        {t("Cancel")}
      </NavLink>
      <button
        className="button"
        type="button"
        onClick={() => {
          if (userRole === "researcher") {
            doAddResearcher();
          } else if (userRole === "biobank") {
            doAddBiobankUser();
          }
        }}
      >
        {t("Add")}
      </button>
      {spinner}
    </div>
  );

  return (
    <>
      <Row>
        <Col lg={4}>
          <h2 className={styles.subheader}>{t("survey_add_new_user")}</h2>
          <p className={"section-description"}>
            {t("survey_add_user_description")}
          </p>
        </Col>
        <Col lg={8}>
          <label>{t("survey_add_user_email")}</label>
          <SurveyFindUser setSelectedValue={setSelectedValue} />
          {showBiobankSelect && (
            <SelectBiobank
              onChange={setBiobank}
              disabled={userHasBiobank}
              initialValue={selectedValue && selectedValue.biobankid}
            />
          )}
          {options}
          {submit}
        </Col>
      </Row>
    </>
  );
};

const SurveyAddUser = ({ surveyId }) => {
  const { users, loading, error, refresh } = useFetchSurveyUsers(surveyId);
  // const { t } = useTranslation();

  if (error) {
    return <p>Nothing loads for now</p>;
  }

  return (
    <Container>
      <SurveyAddUserForm surveyId={surveyId} onChange={refresh} />
      <HorizontalLine />
      {loading ? (
        <p>Loading</p>
      ) : (
        <SurveyAddUserList
          surveyId={surveyId}
          users={users}
          onDelete={refresh}
        />
      )}
    </Container>
  );
};

export default SurveyAddUser;
