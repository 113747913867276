const validFields = [
  "title",
  "title_fi",
  "title_sv",
  "description",
  "description_fi",
  "description_sv",
  "intro",
  "intro_fi",
  "intro_sv",
  "studyid",
  "type",
];

export const filterInitialValues = (values) =>
  Object.keys(values)
    .filter((key) => validFields.includes(key))
    .reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});
