import InfoBlock from "../InfoBlock/InfoBlock";
import React from "react";
import { useTranslation } from "react-i18next";
import project from "../../assets/img/project.svg";

const EmptyProjectListPlaceholder = ({ title, type }) => {
  const { t } = useTranslation();
  return (
    <InfoBlock
      title={t(`no_${type}_projects_placeholder`)}
      iconSrc={project}
      description={""}
      button={null}
    />
  );
};

export default EmptyProjectListPlaceholder;
