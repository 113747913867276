import React from "react";
import { Form, Col, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ProjectSelect = ({ selectedProjectId, onProjectChange, projects }) => {
  const { t } = useTranslation();
  return (
    <Container style={{ marginBottom: "20px" }}>
      <Row>
        <Col xs={3}>
          <Form.Group controlId="projectSelect">
            <Form.Label>{t("projectselect_select")}</Form.Label>
            <Form.Control
              as="select"
              value={selectedProjectId}
              onChange={onProjectChange}
            >
              <option value="">{t("projectselect_all_projects")}</option>
              {projects &&
                projects.map((project) => (
                  <option key={project.studyid} value={project.studyid}>
                    {project.title}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectSelect;
