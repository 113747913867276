const surveyUrl = "/surveys";

export const getStaticUrl = () => "/static";

// Surveys
export const getSurveyListUrl = () => `${surveyUrl}`;
export const getSurveyEditUrl = (surveyId) => `${surveyUrl}/${surveyId}/edit`;
export const getSurveyAddUserUrl = (surveyId) =>
  `${surveyUrl}/${surveyId}/users`;
export const getSurveyUploadSubjectsUrl = (surveyId) =>
  `${surveyUrl}/${surveyId}/subjects`;
export const getSurveyUploadResultsUrl = (surveyId) =>
  `${surveyUrl}/${surveyId}/results`;
export const getActiveSurveyListUrl = () => `${surveyUrl}`;
export const archiveSuffix = "?deleted=1";
export const getArchivedSurveyListUrl = () => `${surveyUrl}${archiveSuffix}`;

// Projects
const projectUrl = "/studies";
export const getProjectListUrl = () => `${projectUrl}`;
export const getProjectEditUrl = (projectId) =>
  `${projectUrl}/${projectId}/edit`;
export const getActiveProjectListUrl = () => `${projectUrl}`;
export const getArchivedProjectListUrl = () => `${projectUrl}${archiveSuffix}`;
