import React from "react";
import propTypes from "prop-types";
import Dropdown from "react-dropdown";
import { useTranslation } from "react-i18next";

import "react-dropdown/style.css";
import styles from "./SurveySelectBiobank.module.scss";

const getBiobanks = (translation) => {
  return [
    {
      label: translation("auria_biobank"),
      value: 10,
    },
    {
      label: translation("helsinki_biobank"),
      value: 20,
    },
    {
      label: translation("biobank_of_eastern_finland"),
      value: 30,
    },
    {
      label: translation("central_finland_biobank"),
      value: 40,
    },
    {
      label: translation("northern_finland_biobank_borealis"),
      value: 50,
    },
    {
      label: translation("tampere_biobank"),
      value: 60,
    },
    {
      label: translation("arctic_biobank"),
      value: 70,
    },
    {
      label: translation("thl_biobank"),
      value: 80,
    },
  ];
};

const SelectBiobank = ({ onChange, disabled }) => {
  const { t } = useTranslation();
  const biobanks = getBiobanks(t);
  const defaultOption = 0;

  return (
    <div className={styles.reactDropdownContainer}>
      <label>{t("survey_set_user_biobank")}</label>
      <Dropdown
        options={biobanks}
        onChange={onChange}
        value={defaultOption}
        placeholder={t("select_biobank")}
        disabled={disabled}
      />
    </div>
  );
};

SelectBiobank.propTpes = {
  onChange: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

export default SelectBiobank;
