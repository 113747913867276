import React from "react";
import SurveyEditForm from "../../components/SurveyEdit/SurveyEditForm";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../components/LoadingWrapper";
import LoadingForm from "../../components/LoadingForm";
import useFetchProjects from "../../hooks/useFetchProjects";

const SurveyAddPage = () => {
  const { studyId } = useParams();
  const { t } = useTranslation();
  const {
    projects,
    loading: projectsLoading,
    error: projectsError,
  } = useFetchProjects();

  return (
    <div className="App page-user-surveylist">
      <Title>{t("survey_add_title")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <LoadingWrapper
        isLoading={projectsLoading}
        isInErrorState={!!projectsError}
        errorMsg={t("surveyedit_form_error_loading")}
        loadingComponent={<LoadingForm />}
      >
        <SurveyEditForm
          initialValues={{
            ...(studyId ? { studyid: studyId } : {}),
          }}
          projects={projects}
        />
      </LoadingWrapper>
    </div>
  );
};

export default SurveyAddPage;
