import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import i18n from "../i18n";
import Papa from "papaparse";

import axios from "../helpers/axios";
import config from "../config";

import { logError } from "../helpers/logger";
import createDownload from "../helpers/fileDownloader";

import {
  STUDY_PARTICIPANTS_GET,
  STUDY_PARTICIPANTS_FAILURE,
} from "./studyParticipants.actions";

const studyParticipantsGet = () => ({ type: STUDY_PARTICIPANTS_GET });

const studyParticipantsSuccess = (participants) => {
  if (participants.length) {
    const csv = createStudyParticipantsCSV(participants);
    createDownload(csv, "study_participants");
  } else {
    toastr.info(i18n.t("participants_info"), i18n.t("participants_not_found"));
  }
};

const studyParticipantsFailure = (code, message) => ({
  type: STUDY_PARTICIPANTS_FAILURE,
  code,
  message,
});

export const useGetStudyParticipants = (studyId) => {
  const dispatch = useDispatch();
  const fetchStudyParticipants = useCallback(async () => {
    dispatch(studyParticipantsGet());
    try {
      const res = await axios.get(
        `${config.fingeniousApiUrl}/study/${studyId}/participants`
      );
      if (res.status === 200) {
        const participants = res.data && res.data.data ? res.data.data : [];
        studyParticipantsSuccess(participants);
      }
    } catch (e) {
      if (e.response.status === 403) {
        toastr.error(i18n.t("participants_error_403"));
      }
      logError(e);
      dispatch(studyParticipantsFailure(e.response.status, e.response.message));
    }
  }, [dispatch, studyId]);

  return fetchStudyParticipants;
};

const createStudyParticipantsCSV = (participants) => {
  return Papa.unparse(participants, {
    columns: ["uuid", "studyid", "will_delete"],
  });
};
