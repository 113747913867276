import config from "../config";
import useFetch from "./useFetch";

const useFetchProjects = (isArchived, skip = false) => {
  const { data, loading, error, refresh } = useFetch(
    `${config.fingeniousApiUrl}/study?deleted=${isArchived ? 1 : 0}`,
    skip
  );

  return {
    projects: data,
    loading,
    error,
    refresh,
  };
};

export default useFetchProjects;
