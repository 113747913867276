import { useEffect, useState } from "react";
import axios from "../helpers/axios";
import { toError } from "../helpers/fetchHelper";
import { logError } from "../helpers/logger";

const initialState = null;

const useFetch = (url, skip = false) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialState);
  const [refreshCount, setRefreshCount] = useState(0);

  const refresh = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    if (skip) {
      setLoading(false);
      return;
    }

    const fetchRequest = async () => {
      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setData(response.data.data);
        } else {
          setData(initialState);
          setError({
            error: response.data.message,
          });
        }
      } catch (e) {
        logError(e);
        setData(initialState);
        setError(toError(e));
      } finally {
        setLoading(false);
      }
    };
    fetchRequest();
    return () => {};
  }, [url, refreshCount, skip]);

  return {
    data,
    loading,
    error,
    refresh,
  };
};

export default useFetch;
