// creating new project
export const PROJECT_CREATE = "PROJECT_CREATE";
export const PROJECT_CREATE_SUCCESSFUL = "PROJECT_CREATE_SUCCESSFUL";
export const PROJECT_CREATE_FAILURE = "PROJECT_CREATE_FAILURE";

// updating existing project
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESSFUL = "PROJECT_UPDATE_SUCCESSFUL";
export const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";

export const PROJECT_RESET_FORM_STATE = "PROJECT_RESET_FORM_STATE";

export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_SUCCESSFUL = "PROJECT_DELETE_SUCCESSFUL";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";
